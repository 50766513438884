import React from 'react'
import cn from "classnames";
import styles from "./SocialHub.module.sass";
import { useState } from 'react';
import Carrousel from './components/Carrousel';
import StoryCard from './components/StoryCard';
import StoryWord from './components/StoryWord';
import NewDeck from './components/NewDeck';
import { useEffect } from 'react';
import { fetchCards } from '../../app/cards/cards.slice';
import { useDispatch } from 'react-redux';

const navigation = ["Carrousel", "Story card", "Story Word", "New deck"]

const SocialHub = () => {

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(navigation[0])

    const [selectedCardProp, setSelectedCardProp] = useState('')
    const [selectedDeckProp, setSelectedDeckProp] = useState('')

    useEffect(() => {
        dispatch(
            fetchCards({
              take: 100,
              skip: 0,
            })
        );
    }, [])
    

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30}} className={cn(styles.nav, "")}>
                {navigation.map((x, index) => (
                    <button
                        className={cn(styles.link, {
                        [styles.active]: x === activeTab,
                        })}
                        onClick={() => setActiveTab(x)}
                        key={index}
                    >
                        {x}
                    </button>
                ))}
            </div>
            {activeTab === 'Carrousel' &&
                <Carrousel selectedCardProp={selectedCardProp} setSelectedCardProp={setSelectedCardProp} selectedDeckProp={selectedDeckProp} setSelectedDeckProp={setSelectedDeckProp} />
            }
            {activeTab === 'Story card' &&
                <StoryCard selectedCardProp={selectedCardProp} setSelectedCardProp={setSelectedCardProp} selectedDeckProp={selectedDeckProp} setSelectedDeckProp={setSelectedDeckProp} />
            }
            {activeTab === 'Story Word' &&
                <StoryWord selectedCardProp={selectedCardProp} setSelectedCardProp={setSelectedCardProp} selectedDeckProp={selectedDeckProp} setSelectedDeckProp={setSelectedDeckProp} />
            }
            {activeTab === 'New deck' &&
                <NewDeck selectedCardProp={selectedCardProp} setSelectedCardProp={setSelectedCardProp} selectedDeckProp={selectedDeckProp} setSelectedDeckProp={setSelectedDeckProp} />
            }
        </div>
    );
}

export default SocialHub;