import React from 'react'
import cn from 'classnames';
import styles from './Questions.module.sass'
import Card from '../../../../../components/Card';
import { useState } from 'react';
import Switch from '../../../../../components/Switch';
import TextInput from '../../../../../components/TextInput';
import Modal from '../../../../../components/Modal';
import Dropdown from '../../../../../components/Dropdown';
import { useEffect } from 'react';
import { createQuestionText, deleteQuestion, getQuestionsText } from '../../../../../api/texts.service';
import { BiCheckSquare } from 'react-icons/bi';
import Actions from '../../../../../components/Actions';
import { toast } from 'sonner';

const LANGS = ['CN', 'EN', 'ES', 'FR', 'RU', 'PT']

const Questions = ({
    chapters,
    questions,
    setQuestions,
    id
}) => {

    const [viewCorrect, setViewCorrect] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const [activeTitleTab, setActiveTitleTab] = useState('CN')
    const [activeOptionsTab, setActiveOptionsTab] = useState('CN')

    const [loadingTranslationTitle, setLoadingTranslationTitle] = useState(false)
    const [loadingTranslationOptions, setLoadingTranslationOptions] = useState(false)

    const [chapter, setChapter] = useState(0)
    const [chaptersOption, setchaptersOption] = useState([])

    const [isModalDelete, setIsModalDelete] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState('')

    const [objectTitle, setObjectTitle] = useState({
        ES: '',
        EN: '',
        FR: '',
        RU: '',
        PT: '',
        CN: '',
    })
    const [objectTextarea, setObjectTextarea] = useState({
        ES: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
        EN: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
        FR: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
        RU: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
        PT: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
        CN: {
            1: '',
            2: '',
            3: '',
            4: ''
        },
    })

    
    useEffect(() => {
        if(chapters?.length > 0){
            setchaptersOption(chapters?.map((c, i)=>{
                return{
                    label: `Chapter ${i+1}`,
                    value: i
                }
            }))
        }
    }, [chapters])
    
    useEffect(() => {
        if(id){
            handleGetQuestions()
        }
    }, [id])

    const handleDelete = async () =>{
        let response = await deleteQuestion(selectedQuestion)
        toast.success('Deleted succesfully')
        handleGetQuestions()
        setTimeout(() => {
            setIsModalDelete(false)
        }, 1000);
    }
    
    const handleSelectQuestion = (question) =>{
        setSelectedQuestion(question)

        let objectTextarea_ = objectTextarea
        let objectTitle_ = objectTitle

        question?.questions.forEach((c)=>{
            objectTitle_[c.lang?.toUpperCase()] = c?.trans
        })

        question?.answers.forEach((c, i)=>{
            objectTextarea_['EN'][`${i+1}`] = c?.find(a => a?.lang === 'en')?.trans
            objectTextarea_['ES'][`${i+1}`] = c?.find(a => a?.lang === 'es')?.trans
            objectTextarea_['FR'][`${i+1}`] = c?.find(a => a?.lang === 'fr')?.trans
            objectTextarea_['RU'][`${i+1}`] = c?.find(a => a?.lang === 'ru')?.trans
            objectTextarea_['PT'][`${i+1}`] = c?.find(a => a?.lang === 'pt')?.trans
            objectTextarea_['CN'][`${i+1}`] = c?.find(a => a?.lang === 'cn')?.trans
        })

        setObjectTitle(objectTitle_)
        setChapter(question?.chapter)
        setObjectTextarea(objectTextarea_)
        setIsModalVisible(true)
    }

    const handleGetQuestions = async () =>{
        let response = await getQuestionsText(id)

        if(response?.data?.results){
            setQuestions(response?.data?.results)
        }
    }

    const handleAddQuestion = async () =>{

        let obj_ = Object.entries(objectTextarea)

        let arrayResp1 = []
        let arrayResp2 = []
        let arrayResp3 = []
        let arrayResp4 = []

        obj_.forEach(c=>{
            arrayResp1.push({
                active: true,
                lang: c?.[0]?.toLowerCase(),
                trans: c?.[1]?.['1']
            })
            arrayResp2.push({
                active: false,
                lang: c?.[0]?.toLowerCase(),
                trans: c?.[1]?.['2']
            })
            arrayResp3.push({
                active: false,
                lang: c?.[0]?.toLowerCase(),
                trans: c?.[1]?.['3']
            })
            arrayResp4.push({
                active: false,
                lang: c?.[0]?.toLowerCase(),
                trans: c?.[1]?.['4']
            })
        })

        let json = {
            answers: [arrayResp1,arrayResp2,arrayResp3,arrayResp4],
            questions: Object.entries(objectTitle).map((c)=>{
                return{
                    active: true,
                    lang: c?.[0]?.toLowerCase(),
                    trans: c?.[1]
                }
            }),
            text: id,
            chapter: chapter
        }

        if(selectedQuestion?._id){
            json._id = selectedQuestion?._id
        }

        let response = await createQuestionText(json)

        handleGetQuestions()

        console.log("response", response)

        if(response.status === 200){
            if(selectedQuestion?._id){
                toast.success('Saved succesfully')
            }else{
                toast.success('Question created succesfully')
            }
            setTimeout(() => {
                setIsModalVisible(false)
            }, 1000);
        }

    }

    const handleTranslateTitle = async () =>{
        setLoadingTranslationTitle(true)
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization

        let TRANS_LANGS = ['en', 'es', 'pt', 'fr', 'ru']

        let objectTitle_ = objectTitle

        for(let lang_ of TRANS_LANGS){
            let response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    title: objectTitle_['CN'],
                    lang: lang_
                }),
                headers: {
                    'Content-Type': 'application/json',
                    ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                }
            })
            let response2 = await response.json();
            let translation = response2?.translations?.[0]?.text
            objectTitle_[lang_.toUpperCase()] = translation
            
        }
        
        toast.success('Translated succesfully')
        setLoadingTranslationTitle(false)
        setObjectTitle(objectTitle_)
        
    }

    

    const handleTranslateOptions = async () =>{
        setLoadingTranslationOptions(true)
        const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
        const authorizationHeader = { 'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).token}` }; // Crea el encabezado Authorization

        let TRANS_LANGS = ['en', 'es', 'pt', 'fr', 'ru']

        let objectTextarea_ = objectTextarea
        let arrayNumbers = ['1', '2', '3', '4']

        for(let lang_ of TRANS_LANGS){
            for(let num_ of arrayNumbers){
                let response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: objectTextarea_['CN'][num_],
                        lang: lang_
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...authorizationHeader // Agrega el encabezado Authorization a los encabezados de solicitud
                    }
                })
                let response2 = await response.json();
                let translation = response2?.translations?.[0]?.text
                objectTextarea_[lang_.toUpperCase()][num_] = translation
            }
            
        }
        
        toast.success('Translated succesfully')
        setLoadingTranslationOptions(false)
        setObjectTextarea(objectTextarea_)
    }

    return (
        <div style={{marginTop: '1em'}}>
            <Modal
                visible={isModalDelete}
                onClose={() => {
                    setSelectedQuestion('');
                    setIsModalDelete(false);
                }}
            >
                <div style={{ margin: "48px 6px", width: "100%", fontSize: 16 }}>Are you sure you want to delete the question?</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                    className={cn("button-stroke button-small", styles.button)}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                        setSelectedQuestion('');
                        setIsModalDelete(false);
                    }}
                >
                    <span>Cancel</span>
                </button>
                <button className={cn("button-stroke-red button-small", styles.button)} onClick={handleDelete}>
                    <span>Delete</span>
                </button>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            >
                <div>
                    <div style={{fontSize: 18}}>
                        Add Question
                    </div>
                    <div style={{marginTop: 25, marginBottom: 25, borderTop: '1px solid lightgray'}} />
                    <Dropdown
                        className={styles.field}
                        classDropdownLabel={styles.label}
                        setValue={setChapter}
                        label="Select chapter"
                        value={chapter}
                        white
                        options={chaptersOption}
                    />
                    <div style={{marginTop: 30}}>
                        <label style={{fontSize: 14, display: 'flex', alignItems: 'center'}}>
                            <span>
                                Question Subject
                            </span>
                            <button onClick={()=>handleTranslateTitle()} style={{marginLeft: 20}} className={cn("button-stroke button-small", styles.button)} >
                                {loadingTranslationTitle ? (
                                    <>
                                        <svg width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                                    <stop stop-color="#000" stop-opacity="0" offset="0%"/>
                                                    <stop stop-color="#000" stop-opacity=".631" offset="63.146%"/>
                                                    <stop stop-color="#000" offset="100%"/>
                                                </linearGradient>
                                            </defs>
                                            <g fill="none" fill-rule="evenodd">
                                                <g transform="translate(1 1)">
                                                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="0.9s"
                                                            repeatCount="indefinite" />
                                                    </path>
                                                    <circle fill="#000" cx="36" cy="18" r="1">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="0.9s"
                                                            repeatCount="indefinite" />
                                                    </circle>
                                                </g>
                                            </g>
                                        </svg>
                                    </>
                                ) : (
                                    <span>Auto-translate</span>
                                )}
                            </button>
                        </label>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                            {LANGS.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                    [styles.active]: x === activeTitleTab,
                                    })}
                                    onClick={() => setActiveTitleTab(x)}
                                    key={index}
                                >
                                    {x}
                                </button>
                            ))}
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                type="text"
                                required
                                placeholder="Text title here"
                                value={objectTitle?.[activeTitleTab] || ''}
                                onChange={(e)=>setObjectTitle(objectTitle => {return{...objectTitle, [activeTitleTab]: e.target.value}})}
                            />
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <label style={{fontSize: 14, display: 'flex', alignItems: 'center'}}>
                            <span>
                                Question Options
                            </span>
                            <button onClick={()=>handleTranslateOptions()} style={{marginLeft: 20}} className={cn("button-stroke button-small", styles.button)} >
                                {loadingTranslationOptions ? (
                                    <>
                                        <svg width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                                    <stop stop-color="#000" stop-opacity="0" offset="0%"/>
                                                    <stop stop-color="#000" stop-opacity=".631" offset="63.146%"/>
                                                    <stop stop-color="#000" offset="100%"/>
                                                </linearGradient>
                                            </defs>
                                            <g fill="none" fill-rule="evenodd">
                                                <g transform="translate(1 1)">
                                                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="0.9s"
                                                            repeatCount="indefinite" />
                                                    </path>
                                                    <circle fill="#000" cx="36" cy="18" r="1">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="0.9s"
                                                            repeatCount="indefinite" />
                                                    </circle>
                                                </g>
                                            </g>
                                        </svg>
                                    </>
                                ) : (
                                    <span>Auto-translate</span>
                                )}
                            </button>
                        </label>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}} className={cn(styles.nav, "tablet-hide")}>
                            {LANGS.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                    [styles.active]: x === activeOptionsTab,
                                    })}
                                    onClick={() => setActiveOptionsTab(x)}
                                    key={index}
                                >
                                    {x}
                                </button>
                            ))}
                        </div>
                        <div style={{marginTop: 10, display: 'flex', alignItems: 'center', position: 'relative', width: '100%'}}>
                            <TextInput
                                type="text"
                                style={{width: '100%'}}
                                className="w-full"
                                required
                                placeholder="Option 1"
                                value={objectTextarea?.[activeOptionsTab]?.['1'] || ''}
                                onChange={(e)=>setObjectTextarea(objectTextarea => { return{...objectTextarea, [activeOptionsTab]: {...objectTextarea[activeOptionsTab], 1: e.target.value}}})}
                            />
                            <div style={{position: 'absolute', right: 20, fontSize: 12, color: 'green'}}>
                                Correct Answer
                            </div>
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                type="text"
                                required
                                placeholder="Option 2"
                                value={objectTextarea?.[activeOptionsTab]?.['2'] || ''}
                                onChange={(e)=>setObjectTextarea(objectTextarea => { return{...objectTextarea, [activeOptionsTab]: {...objectTextarea[activeOptionsTab], 2: e.target.value}}})}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                type="text"
                                required
                                placeholder="Option 3"
                                value={objectTextarea?.[activeOptionsTab]?.['3'] || ''}
                                onChange={(e)=>setObjectTextarea(objectTextarea => { return{...objectTextarea, [activeOptionsTab]: {...objectTextarea[activeOptionsTab], 3: e.target.value}}})}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextInput
                                type="text"
                                required
                                placeholder="Option 4"
                                value={objectTextarea?.[activeOptionsTab]?.['4'] || ''}
                                onChange={(e)=>setObjectTextarea(objectTextarea => { return{...objectTextarea, [activeOptionsTab]: {...objectTextarea[activeOptionsTab], 4: e.target.value}}})}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 30}}>
                        <button onClick={handleAddQuestion} className={cn("button-black button-small", styles.favorite)}>
                            {selectedQuestion ? 'Save' : 'Add Question'}
                        </button>
                    </div>
                </div>
            </Modal>
            <Card
                title="Questions"
                classTitle="title-blue"
                head={<div style={{ display: 'flex', alignItems: 'center'}} className={cn(styles.nav, "tablet-hide")}>
                    <span style={{marginRight: 20}}>
                        View correct
                    </span>
                    <Switch value={viewCorrect} onChange={()=>setViewCorrect(!viewCorrect)} />
                </div>}
            >
                <div>
                    {questions?.map((c, i)=>(
                        <div style={{borderRadius: '12px', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '2px solid lightgray', padding: '16px', position: 'relative', marginTop: i !== 0 ? 15 : 0 }}>
                            <div>
                                <div>
                                    {c?.questions?.find(c => c?.lang === 'en')?.trans}
                                </div>
                                <div style={{color: 'gray', fontSize: 14}}>
                                    <span>
                                        Chapter {c?.chapter+1}
                                    </span>
                                </div>
                                {viewCorrect &&
                                    <div style={{color: '#2A85FF', fontSize: 14, display: 'flex', alignItems: 'center'}}>
                                        <BiCheckSquare />
                                        <span style={{marginLeft: 4}}>
                                            {c?.answers?.[0]?.find(c => c?.lang === 'en')?.trans}
                                        </span>
                                    </div>
                                }
                            </div>
                            <Actions
                                className={styles.actions}
                                classActionsHead={styles.actionsHead}
                                items={[
                                    {
                                        title: "Edit",
                                        icon: "edit",
                                        action: () => handleSelectQuestion(c),
                                    },
                                    {
                                        title: "Delete",
                                        icon: "trash",
                                        action: () => {
                                            setIsModalDelete(true)
                                            setSelectedQuestion(c)
                                        },
                                    },
                                ]}
                                small
                            />
                        </div>
                    ))}
                </div>
                <button onClick={()=>{
                    setIsModalVisible(true)
                    setSelectedQuestion('')
                    setObjectTitle({
                        ES: '',
                        EN: '',
                        FR: '',
                        RU: '',
                        PT: '',
                    })
                    setObjectTextarea({
                        ES: {
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        },
                        EN: {
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        },
                        FR: {
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        },
                        RU: {
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        },
                        PT: {
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        },
                    })
                }} onMouseOver={(e)=>{e.target.style.background = '#2A85FF39'}} onMouseLeave={(e)=>{e.target.style.background = '#2A85FF29'}} style={{width: '100%', marginTop: 20, background: '#2A85FF29', cursor: 'pointer', color: '#2A85FF', padding: 12, borderRadius: '12px' }}>
                    + Add question
                </button>
            </Card>
        </div>
    );
}

export default Questions;