import React from 'react'
import styles from "./StoryWord.module.sass";
import { toPng } from 'html-to-image';
import cn from "classnames";
import JSZip from 'jszip'
import { useRef } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Textfit } from '@ayushmw/react-textfit';
import { useDispatch, useSelector } from 'react-redux';
import { parseSearchCardTags } from '../../../../utils';
import { fetchCards } from '../../../../app/cards/cards.slice';
import DropdownSearch from '../../../../components/DropdownSearch';
import { useEffect } from 'react';
import Dropdown from '../../../../components/Dropdown';
import { fetchDecks } from '../../../../app/decks/decks.slice';
import Switch from '../../../../components/Switch';
import { BiDownload, BiImage, BiPalette, BiVideo, BiX } from 'react-icons/bi';
import { AiFillSound } from 'react-icons/ai';
import Checkbox from '../../../../components/Checkbox';


const LANGUAGES = [
    {label: 'English', value: 'en'},
    {label: 'Español', value: 'es'},
    {label: 'Portuguese', value: 'pt'},
    {label: 'French', value: 'fr'},
    {label: 'Russian', value: 'ru'},
]

const StoryWord = ({ selectedCardProp, selectedDeckProp, setSelectedCardProp, setSelectedDeckProp }) => {

    const dispatch = useDispatch();
    const { decks, cards } = useSelector((state) => state);

    const [isLoading, setIsLoading] = useState(false)

    const [decksOptions, setDecksOptions] = useState(decks)

    const [selectedCard, setSelectedCard] = useState('')
    const [selectedDeck, setSelectedDeck] = useState('')
    const [languageSelected, setLanguageSelected] = useState(['en'])

    const [isBackground, setIsBackground] = useState(true)
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [isPlusBadge, setIsPlusBadge] = useState(false)
    const [isLanguageFlags, setIsLanguageFlags] = useState(true)

    const [colorSelected, setColorSelected] = useState('#2ED0A0')
    const [imageSelected, setImageSelected] = useState('')

    const ref_1 = useRef(null)
    const ref_2 = useRef(null)
    const ref_3 = useRef(null)
    const ref_4 = useRef(null)

    const handleSearchCard = (term) => {
        dispatch(
            fetchCards({
                take: 100,
                skip: 0,
                search: term,
            })
        );
    };

    useEffect(() => {
        if(selectedCardProp && selectedDeckProp){
            setSelectedCard(selectedCardProp)
            setSelectedDeck(selectedDeckProp)
        }
    }, [selectedDeckProp, selectedCardProp])

    const handleSearchDeck = (term) => {
        dispatch(
            fetchDecks({
                take: 100,
                skip: 0,
                search: term,
            })
        );
    };

    const handleDownloadVideos = () =>{
        console.log("descargando videos...")
    }

    const handleAddImage = (e) =>{
        console.log("e", e.target.files)
        const [file] = e.target.files
        if (file) {
            let url = URL.createObjectURL(file)
            setImageSelected(url)
        }
    }

    const handleDownloadPNG = useCallback((type) => {
        setIsLoading(true)
        if (ref_1.current === null) {
            return
        }
        
        if(type === 1 || type === 'all'){
            toPng(ref_1.current, { cacheBust: false, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'storyword_1.png'
                    console.log("dataUrl", dataUrl)
                    link.href = dataUrl
                    link.click()
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }

        if(type === 2 || type === 'all'){
            toPng(ref_2.current, { cacheBust: false, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'storyword_2.png'
                    console.log("dataUrl", dataUrl)
                    link.href = dataUrl
                    link.click()
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }

        if(type === 3 || type === 'all'){
            toPng(ref_3.current, { cacheBust: false, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'storyword_3.png'
                    console.log("dataUrl", dataUrl)
                    link.href = dataUrl
                    link.click()
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }

        if(type === 4 || type === 'all'){
            toPng(ref_4.current, { cacheBust: false, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'storyword_4.png'
                    console.log("dataUrl", dataUrl)
                    link.href = dataUrl
                    link.click()
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
    }, [ref_1, ref_2, ref_3, ref_4])


    useEffect(() => {
        console.log("decks", decks)
        console.log("selectedCard", selectedCard)


    }, [selectedCard,decks])

    useEffect(() => {
        dispatch(fetchDecks())
    }, [])

    useEffect(() => {
        if(isDarkMode){
            setColorSelected('#424557')
        }else{
            setColorSelected('#2ED0A0')
        }
    }, [isDarkMode])
    
    

    return (
        <div className={styles.containerFlex}>
            <div className={styles['col-span-2']} style={{background: 'white', padding: 20, justifyContent: 'center', display: 'flex'}}>
                <div style={{width: '700px'}}>
                    {(!selectedCard || !selectedDeck) ? (
                        <div>
                            <h2 style={{color: 'gray'}}>Please select a Card & Deck to proceed...</h2>
                        </div>
                    ) : (
                        <div className={styles.grid2}>

                            <div ref={ref_1} style={{ // 1 Panel
                                width: '350px', 
                                height: '622px', 
                                background: "transparent",
                                borderRadius: '6px',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                display: 'flex',
                                position: 'relative'
                            }}>
                                {/* <div style={{position: 'absolute', zIndex: 0, bottom: 0, left: 0, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                    <img src="/images/rectangle.svg" style={{width: '100%'}} />
                                </div> */}
                                <div style={{
                                    width: '250px',
                                    height: '250px',
                                    marginBottom: 50,
                                    background: isDarkMode ? '#2A2C38' : 'white',
                                    borderRadius: '12px',
                                    color: isDarkMode ? 'white' : 'black',
                                    border: isDarkMode ? '0.65px solid #252839' : '0.65px solid #FAFAFB',
                                    boxShadow: isDarkMode ? '0px 4px 0px 0px #1D1F2C' : '0px 4px 0px 0px #F3F3F3',
                                    position: 'relative',
                                }}>
                                    <div style={{width: '100%', textAlign: 'center', fontWeight: '400', position: 'relative'}}>
                                        <div style={{fontSize: '50px'}}>
                                            {selectedCard?.character}
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
                                            <span style={{color: '#D5D5DC', fontSize: '12px'}}>
                                                PY
                                            </span>
                                            <span style={{color: isDarkMode ? '#767EF0' : '#2934D0', fontSize: '16px', marginLeft: 5}}>
                                                {selectedCard?.card?.meaning?.pinyin}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div style={{borderTop: isDarkMode ? '1px solid #565698' : '1px solid #F1F1F5', width: '100%', marginTop: 10}}></div>

                                    <div style={{marginTop: 10}}>
                                        {selectedCard?.card?.meaning?.translations?.map((c)=>{
                                            if(c?.active && languageSelected?.includes(c?.lang)){
                                                return(
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                                        <img style={{width: 20}} src={`/images/flags/${c?.lang}.svg`} />
                                                        <span style={{marginLeft: 5}}>
                                                            {c?.trans?.split(',')?.[0]?.split(';')?.[0]}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>


                            <div ref={ref_2} style={{ // 2 Panel
                                width: '350px', 
                                height: '622px', 
                                background: "transparent",
                                borderRadius: '6px',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                display: 'flex',
                                position: 'relative'
                            }}>
                                {/* <div style={{position: 'absolute', zIndex: 0, bottom: 0, left: 0, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                    <img src="/images/rectangle.svg" style={{width: '100%'}} />
                                </div> */}
                                <div style={{marginBottom: 50, zIndex: 10}}>
                                    <div style={{
                                        background: isDarkMode ? '#2A2C38' : 'white',
                                        borderRadius: '12px',
                                        marginTop: '10px',
                                        paddingInline: 20,
                                        width: '250px',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: isDarkMode ? 'white' : 'black',
                                        textAlign: 'center'
                                    }}>
                                        {selectedCard?.card?.examples?.[0]?.character}
                                    </div>
                                    <div style={{
                                        backgroundColor: '#00000094', 
                                        borderRadius: '12px',
                                        backdropFilter: 'blur(15px)',
                                        marginTop: '10px',
                                        paddingInline: 20,
                                        width: '250px',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: '#939AFF',
                                        textAlign: 'center'
                                    }}>
                                        {selectedCard?.card?.examples?.[0]?.pinyin}
                                    </div>
                                    <div style={{
                                        width: '250px',
                                        background: isDarkMode ? '#2A2C38' : 'white',
                                        borderRadius: '12px',
                                        marginTop: '10px',
                                        paddingInline: 20,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: isDarkMode ? 'white' : 'black',
                                        border: isDarkMode ? '0.65px solid #252839' : '0.65px solid #FAFAFB',
                                        position: 'relative',
                                    }}>

                                        <div style={{marginTop: 10}}>
                                            {selectedCard?.card?.examples?.[0]?.translations?.map((c)=>{
                                                if(c?.active && languageSelected?.includes(c?.lang)){
                                                    return(
                                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5}}>
                                                            <img style={{width: 20}} src={`/images/flags/${c?.lang}.svg`} />
                                                            <span style={{marginLeft: 5}}>
                                                                {c?.trans?.split(',')?.[0]?.split(';')?.[0]}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>


                            <div ref={ref_3} style={{ // 3 Panel
                                width: '350px', 
                                height: '622px', 
                                background: "transparent",
                                borderRadius: '6px',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                display: 'flex',
                                position: 'relative'
                            }}>
                                {/* <div style={{position: 'absolute', zIndex: 0, bottom: 0, left: 0, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                    <img src="/images/rectangle.svg" style={{width: '100%'}} />
                                </div> */}
                                <div style={{marginBottom: 50}}>
                                    <div style={{
                                        background: isDarkMode ? '#2A2C38' : 'white',
                                        borderRadius: '12px',
                                        marginTop: '10px',
                                        width: '250px',
                                        paddingInline: 20,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: isDarkMode ? 'white' : 'black',
                                        textAlign: 'center'
                                    }}>
                                        {selectedCard?.card?.examples?.[1]?.character}
                                    </div>
                                    <div style={{
                                        backgroundColor: '#00000094', 
                                        borderRadius: '12px',
                                        backdropFilter: 'blur(15px)',
                                        width: '250px',
                                        marginTop: '10px',
                                        paddingInline: 20,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: '#939AFF',
                                        textAlign: 'center'
                                    }}>
                                        {selectedCard?.card?.examples?.[1]?.pinyin}
                                    </div>
                                    <div style={{
                                        width: '250px',
                                        background: isDarkMode ? '#2A2C38' : 'white',
                                        borderRadius: '12px',
                                        marginTop: '10px',
                                        paddingInline: 20,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: isDarkMode ? 'white' : 'black',
                                        border: isDarkMode ? '0.65px solid #252839' : '0.65px solid #FAFAFB',
                                        position: 'relative',
                                    }}>

                                        <div style={{marginTop: 10}}>
                                            {selectedCard?.card?.examples?.[1]?.translations?.map((c)=>{
                                                if(c?.active && languageSelected?.includes(c?.lang)){
                                                    return(
                                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5}}>
                                                            <img style={{width: 20}} src={`/images/flags/${c?.lang}.svg`} />
                                                            <span style={{marginLeft: 5}}>
                                                                {c?.trans?.split(',')?.[0]?.split(';')?.[0]}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div ref={ref_4} style={{ // 4 Panel
                                width: '350px', 
                                height: '622px', 
                                backgroundColor: colorSelected,
                                backgroundImage: `url('${imageSelected}')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                borderRadius: '24px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                position: 'relative'
                            }}>
                                <div style={{position: 'absolute', top: 20, left: 0, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                    <div style={{backgroundColor: '#F5F5F5', borderRadius: '999px', paddingInline: '20px', width: '40%', display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}} >
                                            <img src="/images/logo-complete.svg" style={{width: '100%'}} />
                                    </div>
                                </div>

                                <div style={{marginTop: 0}}>
                                    
                                    <div style={{
                                        width: '250px',
                                        height: '300px',
                                        background: isDarkMode ? '#2A2C38' : 'white',
                                        borderRadius: '12px',
                                        paddingInline: '14px',
                                        color: isDarkMode ? 'white' : 'black',
                                        border: isDarkMode ? '0.65px solid #252839' : '0.65px solid #FAFAFB',
                                        boxShadow: isDarkMode ? '0px 4px 0px 0px #1D1F2C' : '0px 4px 0px 0px #F3F3F3',
                                        position: 'relative',
                                    }}>
                                        <div style={{ width: '100%'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, width: '100%'}}>
                                                <img 
                                                    src={selectedDeck?.deck?.avatar?.miniUrl || "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"} 
                                                    style={{width: '100px'}} 
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                                                    }}
                                                />
                                                <div style={{display: 'block'}}>
                                                    {selectedDeck?.deck?.new &&
                                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                            <img 
                                                                src="/images/Logonew.svg"
                                                                style={{width: '50px'}}
                                                            />
                                                        </div>
                                                    }
                                                    {isPlusBadge &&
                                                        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 4}}>
                                                            <img 
                                                                src="/images/Logoplus.svg"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{marginTop: 10}}>
                                            <div style={{textTransform: 'uppercase', color: '#B5B5BE', fontSize: '14px'}}>
                                                {selectedDeck?.deck?.level}
                                            </div>
                                            <div style={{fontSize: '18px'}}>
                                                {selectedDeck?.deck?.title}
                                            </div>
                                        </div>

                                        <div style={{position: 'absolute', bottom: 15, left: 0, width: '100%', paddingInline: '14px'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center', color: isDarkMode ? "#8189FF" : '#2934D0'}}>
                                                    <span style={{fontSize: '24px'}}>
                                                        {selectedDeck?.deck?.cards?.length}
                                                    </span>
                                                    <span style={{marginLeft: 2}}>
                                                        words
                                                    </span>
                                                </div>
                                                <div style={{ color: '#2ED0A0'}}>
                                                    Start now!
                                                </div>
                                            </div>
                                            <div style={{width: '100%', borderRadius: '999px', background: '#EEEEEF', height: '12px'}}></div>
                                        </div>

                                        {isLanguageFlags &&
                                            <div style={{position: 'absolute', bottom: -60, left: 0, width: '100%', paddingInline: '14px'}}>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    {languageSelected?.map((c, i)=>(
                                                        <img src={`/images/flags/${c}.svg`} style={{width: 23, marginLeft: i > 0 ? 10 : 0}} />
                                                    ))}
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>



                        </div>
                    )
                    }
                </div>
            </div>
            <div className={styles.separator}>
                <DropdownSearch
                    className={styles.field}
                    isBackgroundWhite
                    label="Deck"
                    value={selectedDeck}
                    placeholder="Search Decks"
                    setValue={(e)=>{
                        setSelectedDeck(e)
                        setSelectedDeckProp(e)
                    }}
                    options={decks.value.map((deck) => { return {label: deck?.key, value: deck?.key, deck: deck } })}
                    isLoading={decks.isLoading}
                    timeoutSearch={500}
                    onSearch={handleSearchDeck}
                />
                <DropdownSearch
                    className={styles.field}
                    isBackgroundWhite
                    label="Card"
                    isDelete
                    value={selectedCard}
                    placeholder="Search Cards"
                    setValue={(e)=>{
                        setSelectedCard(e)
                        setSelectedCardProp(e)
                    }}
                    options={cards.value.map((card) => parseSearchCardTags(card))}
                    isLoading={cards.isLoading}
                    timeoutSearch={500}
                    onSearch={handleSearchCard}
                />
                <div>
                    <div style={{marginBottom: 10}}>
                        <label style={{fontSize: 14}}>Language</label>
                    </div>
                    <div className={styles?.grid2}>
                        {LANGUAGES?.map((c)=>(
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    className={styles.checkbox}
                                    classCheckboxTick={styles.checkboxTick}
                                    value={languageSelected?.includes(c?.value)}
                                    onChange={() => languageSelected?.includes(c?.value) ? setLanguageSelected(languageSelected=>[...languageSelected?.filter(f => f !== c?.value)]) : setLanguageSelected(languageSelected=>[...languageSelected, c?.value])}
                                />
                                <div style={{ marginLeft: 10}}>
                                    {c?.label}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <Dropdown
                    className={styles.field}
                    classDropdownLabel={styles.label}
                    setValue={setLanguageSelected}
                    value={languageSelected}
                    label="Language"
                    isWhite
                    options={[
                        {label: 'English', value: 'en'},
                        {label: 'Español', value: 'es'},
                        {label: 'Portuguese', value: 'pt'},
                        {label: 'French', value: 'fr'},
                        {label: 'Russian', value: 'ru'},
                    ]}
                /> */}
                <div style={{borderTop: '1px solid lightgray', width: '100%'}}> </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <label>
                        Background
                    </label>
                    <Switch 
                        value={isBackground}
                        onChange={()=>setIsBackground(!isBackground)}
                    />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <label>
                        Dark mode
                    </label>
                    <Switch 
                        value={isDarkMode}
                        onChange={()=>setIsDarkMode(!isDarkMode)}
                    />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <label>
                        Plus badge
                    </label>
                    <Switch 
                        value={isPlusBadge}
                        onChange={()=>setIsPlusBadge(!isPlusBadge)}
                    />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <label>
                        Languages flags
                    </label>
                    <Switch 
                        value={isLanguageFlags}
                        onChange={()=>setIsLanguageFlags(!isLanguageFlags)}
                    />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <label>
                        <BiPalette />
                        <span style={{marginLeft: 5}}>
                            Change bg color
                        </span>
                    </label>
                    <input type="color" value={colorSelected} onChange={(e)=>setColorSelected(e.target.value)} style={{marginLeft: 10}} />
                </div>
                {imageSelected ? (
                    <>
                        <button onClick={()=>setImageSelected('')} style={{background: 'white', width: '100%'}} className={cn("button-small button-stroke", styles.button)}>
                            <BiImage />
                            <span>
                                Remove bg image
                            </span>
                        </button>
                    </>
                ) : (
                    <>
                        <input onChange={handleAddImage} style={{display: 'none'}} type="file" id="file" name="file" accept="image/png, image/jpeg, image/jpg, image/webp" />
                        <label for="file" style={{background: 'white', width: '100%'}} className={cn("button-small button-stroke", styles.button)}>
                            <BiImage />
                            <span>
                                Add bg image
                            </span>
                        </label>
                    </>
                )}
                <div style={{borderTop: '1px solid lightgray', width: '100%'}}> </div>
                <div className={styles.onMobile}>
                    <button disabled={!selectedCard || !selectedDeck} onClick={()=>handleDownloadPNG(1)} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                        <BiDownload />
                        <span>
                            Download 1
                        </span>
                    </button>
                    <button disabled={!selectedCard || !selectedDeck} onClick={()=>handleDownloadPNG(2)} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                        <BiDownload />
                        <span>
                            Download 2
                        </span>
                    </button>
                    <button disabled={!selectedCard || !selectedDeck} onClick={()=>handleDownloadPNG(3)} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                        <BiDownload />
                        <span>
                            Download 3
                        </span>
                    </button>
                    <button disabled={!selectedCard || !selectedDeck} onClick={()=>handleDownloadPNG(4)} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                        <BiDownload />
                        <span>
                            Download 4
                        </span>
                    </button>
                </div>
                <button disabled={!selectedCard || !selectedDeck} onClick={()=>handleDownloadPNG('all')} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                    <BiDownload />
                    <span>
                        Download PNGs
                    </span>
                </button>
                <button disabled={!selectedCard || !selectedDeck} onClick={handleDownloadVideos} style={{ width: '100%'}} className={cn("button-normal", styles.button)}>
                    <BiVideo />
                    <span>
                        Download videos
                    </span>
                </button>
            </div>
        </div>
    );
}

export default StoryWord;